import request from '@/utils/request'

//  活动中心列表
export function newsList(params) {
  return request({
    url: '/api/cceec/activityList',
    method: 'get',
    params: params
  })
}

//  活动中心详情
export function newsDetails(id, params) {
  return request({
    url: '/api/cceec/activityView/' + id,
    method: 'get',
    params: params
  })
}

// 通知公告列表
export function noticeList(params) {
  return request({
    url: '/api/cceec/noticeList',
    method: 'get',
    params: params
  })
}
// 通知公告详情
export function noticeDetails(id, params) {
  return request({
    url: '/api/cceec/noticeView/' + id,
    method: 'get',
    params: params
  })
}
