<template>
  <div class="app-container">
    <!-- 标题 -->
    <div class="title_leftm">
      <div style="float: left;">
        <span class="span_title">{{ $t('m.navbar.notice_title') }}</span>
        <span v-if="this.$language === 'cn'" class="span_sub_title"> / Conference Information</span>
      </div>
    </div>
    <!-- 内容 -->
    <div v-loading="loading" style="min-height: 350px;">
      <div v-if="obj">
        <div style="text-align: center;">
          <h3 style="padding: 0 20px;">
            {{ obj.title }}
          </h3>
          <span>{{ $t('m.hezuo.release_time') }}</span>
          <span>{{ obj.time }}</span>
        </div>
        <div class="isre_conts">
          <div v-html="obj.body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//
import { noticeDetails } from '@/api/cceec/news'
export default {
  name: 'NoticeDetails',
  data() {
    return {
      loading: true,
      id: null,
      obj: null
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = Number(this.$route.query.id)
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      const data = {
        languageType: this.$language === 'cn' ? 1 : 2,
        preview: this.$route.query.preview
      }
      this.loading = true
      noticeDetails(this.id, data).then(res => {
        this.loading = false
        console.log(res)
        this.obj = res.data
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .title_leftm {
    margin: 20px auto 0;
    width: 1200px;
    height: 40px;
    white-space: nowrap;
    border-bottom: 1px #dddbdb dashed;
    .span_title {
      font-size: 18px;
      color: #2890eb;
    }
    .span_sub_title {
      color: #999;
      font-size: 14px;
      font-family: arial;
    }
  }
  h3 {
    font-weight: lighter;
    margin-bottom: 30px;
    font-size: 20px !important;
  }
  .isre_conts {
    position: relative;
    margin: 30px auto 0;
    width: 1000px;
    font-size: 14px;
    line-height: 30px;
    word-break: normal;
    word-wrap: break-word;
    img {
      width: 100%;
    }
    p {
      margin: 0 0 10px;
    }
    .a-link {
      color: #006bbf;
    }
  }
</style>
